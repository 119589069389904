:root {
  --header-height: 60px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden {
  display: none;
}

.error {
  color: #b44
}

.center-text {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, #root, #site {
  height: 100%;
  width: 100%;
}
#site {
  display: flex;
  flex-direction: column;
}

.link {
  color: #0366d6;
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

a.link {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: #0366d6;
  cursor: pointer;
}

.link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0366d6;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

header {
  z-index: 999;
  height: var(--header-height);
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
header #logo img {
  height: calc(var(--header-height) - 10px);
  object-fit: contain;
}
header nav {
  flex: 1;
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: var(--header-height);
  left: 0px;
  right: 0px;
  height: 0px;
  pointer-events: none;
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
header nav > * {
  pointer-events: all;
}
header nav.open {
  height: calc(100% - var(--header-height));
}
header nav .nav-links {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
}
header nav .nav-links > * {
  margin: 5px;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-x: auto;
}

aside {
  width: 250px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 100;
  /* pointer-events: none; */
  transition: width 0.25s;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  border-right: 1px solid #ddd;
}
aside.open {
  width: 250px;
}
#toggleAside {
  float: right;
  transition: transform 0.25s;
}
aside.open #toggleAside {
  transform: scaleX(-1);
}
.aside-items {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.aside-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #000;
}
.aside-item:hover {
  color: #444;
}
.aside-item span {
  font-size: 1.2rem;
}

main {
  flex: 1;
  overflow: auto;
  position: absolute;
  top: var(--header-height);
  bottom: 0px;
  right: 0px;
  left: 0px;
}

aside + main {
  padding-left: 50px;
}

main > * {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 960px) {
  header nav {
    position: relative;
    top: 0px;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    overflow: visible;
  }
  header nav.open {
    height: auto;
  }
  header nav .nav-links {
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: none;
  }
  header #toggleNav {
    display: none;
  }
}
@media (min-width: 1300px) {
  aside {
    width: 250px;
  }
  aside #toggleAside {
    display: none;
  }
  main {
    padding-left: 0px;
    padding-right: 0px;
  }
  main > * {
    max-width: 800px;
  }
}
@media (min-width: 1500px) {
  main {
    padding-left: 0px;
    padding-right: 0px;
  }
  main > * {
    max-width: 1000px;
  }
}
@media (min-width: 1600px) {
  main > * {
    max-width: 1100px;
  }
}
@media (min-width: 1800px) {
  main > * {
    max-width: 1300px;
  }
}
main > .fill {
  max-width: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 100%;
  width: 100%;
}
aside + main > .fill {
  margin-left: -50px;
}


.map-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  flex: 1;
}
.map-container .map-actions {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;

  >* {
    margin-left: 10px;
  }
  >*:first-child {
    margin-left: 0px;
  }
}
.map-container .map-legend {
  position: absolute;
  top: 50px;
  left: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.legend-key {
  width: 100%;
  height: 40px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.legend-key-icon {
  height: 20px;
  width: 20px;
  margin: 5px;
  border-radius: 50%;
}
.legend-key-text {
  flex: 1;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  z-index: 99;
}

.map-list {
  width: 100%;
  height: 222px;
  z-index: 99;
  display: flex;
}
.map-cards {
  margin-top: 40px;
  flex: 1;
  overflow: auto;
}
.map-list-actions {
  position: absolute;
  right: 0px;
  width: 100%;
  background-color: white;
  border-end-start-radius: 5px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  z-index: 2;
  display: flex;
  flex-direction: row;
}
.map-list-actions > * {
  margin: 5px;
}
.search {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.search input {
  flex: 1;
  margin-right: 2px;
}
@media (min-width: 700px) {
  .map-container {
    flex-direction: row;
  }
  .map-list {
    width: 350px;
    height: 100%;
  }
  .map-list-actions {
    width: 350px;
  }
}
@media (min-width: 1300px) {
  .map-list {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}
.map-card {
  padding: 5px;
  margin: 5px;
}
.map-card.selected h3 {
  color: #00aa00;
}
.map-card .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 5px 20px;
}
.map-card .actions > * {
  margin-right: 5px;
}
.map-card .actions > *:last-child {
  margin-right: 0px
}

.list-actions {
  display: flex;
  flex-direction: row;
}
.list-actions > * {
  margin: 5px;
}

.table-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2px;
}

.th-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon-button {
  width: 50px;
  height: 50px;
  padding: 5px;
  cursor: pointer;
}
.icon-button.icon-button-small {
  width: 35px;
  height: 35px;
}
.icon-button:hover {
  color: #444;
}
.icon-button > * {
  height: 100% !important;
  width: 100% !important;
}

.circle-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: black;
  background-color: white;
  cursor: pointer;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.circle-button img {
  height: 100%;
  width: 100%;
  padding: 2px;
  object-fit: contain;
}
.circle-button.medium {
  height: 40px;
  width: 40px;
  padding: 4px;
  font-size: 1.5rem;
}
.circle-button.large {
  height: 50px;
  width: 50px;
  padding: 5px;
  font-size: 2rem;
}
.circle-button.disabled {
  color: #ddd;
}
.circle-button:hover {
  color: #444;
  background-color: #fafafa;
}
.circle-button.circle-button-primary {
  color: #1b6ec2;
}
.circle-button.circle-button-primary:hover {
  color: #0b5ed7;
}
.circle-button.circle-button-success {
  color: #199e1c;
}
.circle-button.circle-button-success:hover {
  color: #148017;
}
.circle-button.circle-button-alternate {
  color: #b87d1f;
}
.circle-button.circle-button-alternate:hover {
  color: #946418;
}
.circle-button.circle-button-danger {
  color: #c75938;
}
.circle-button.circle-button-danger:hover {
  color: #9c4227;
}
.circle-button.disabled {
  color: #ddd;
}
.circle-button.disabled:hover {
  color: #ddd;
  background-color: white;
}
.circle-button.disabled {
  color: #ddd;
}
.circle-button:hover {
  color: #444;
  background-color: #fafafa;
}

.circle-button.circle-button-primary img {
  filter: invert(0.5) sepia(1) hue-rotate(180deg) saturate(1000%) brightness(0.8);
}
.circle-button.circle-button-primary:hover img {
  filter: invert(0.5) sepia(1) hue-rotate(180deg) saturate(1000%) brightness(0.7);
}
.circle-button.circle-button-success img {
  filter: invert(0.5) sepia(1) hue-rotate(90deg) saturate(1000%) brightness(0.7);
}
.circle-button.circle-button-success:hover img {
  filter: invert(0.5) sepia(1) hue-rotate(90deg) saturate(1000%) brightness(0.6);
}
.circle-button.circle-button-alternate img {
  filter: invert(0.5) sepia(1) hue-rotate(0deg) saturate(1000%) brightness(0.8);
}
.circle-button.circle-button-alternate:hover img {
  filter: invert(0.5) sepia(1) hue-rotate(0deg) saturate(1000%) brightness(0.7);
}
.circle-button.circle-button-danger img {
  filter: invert(0.5) sepia(1) hue-rotate(-30deg) saturate(1000%) brightness(0.8);
}
.circle-button.circle-button-danger:hover img {
  filter: invert(0.5) sepia(1) hue-rotate(-30deg) saturate(1000%) brightness(0.7);
}
.circle-button.disabled img {
  color: #ddd;
}
.circle-button.disabled:hover img {
  color: #ddd;
  background-color: white;
}

.circle-button-collapse .collapse {
  display: none;
  position: absolute;
}
.circle-button-collapse .collapse.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.circle-button-collapse .collapse .collapse-content {
  height: 0px;
  transition: height 0.5s;
}
.circle-button-collapse .collapse.open .collapse-content {
  height: unset;
}
.circle-button-collapse .collapse-content .circle-button {
  border: 1px solid #ddd;
  margin: 2px 0px;
}

.labelled-text-field {
  display: flex;
  flex-direction: row;
  margin: 3px;
  border-bottom: 1px solid #ddd;
}
.labelled-text-field .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-text-field .text {
  flex: 3;
  flex-wrap: wrap;
}

.labelled-text-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-text-box .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-text-box input {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}
.labelled-text-box .input {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.labelled-text-box-partial {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.labelled-text-box-partial .label-partial {
  min-width: 70px;
  text-align: end;
  margin: 5px;
}

.labelled-text-box-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-text-box-check .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-text-box-check input {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
  height: 28px;
}
.labelled-text-box-check .check {
  border: 1px solid #ddd;
  margin-left: 10px;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.labelled-text-box-check .check:hover {
  color: #444;
}
.labelled-text-box-check .check:active {
  color: #888;
}

.labelled-text-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-text-area .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-text-area textarea {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}
.labelled-text-area .input {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.labelled-text-area-partial {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.labelled-text-area-partial .label-partial {
  min-width: 70px;
  text-align: end;
  margin: 5px;
}

.labelled-check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-check-box .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-check-box input {
  height: 28px;
  width: 28px;
  border: 1px solid #000;
  border-radius: 2px;
}
.labelled-check-box .input {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.labelled-check-box-partial {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.labelled-check-box-partial .label-partial {
  min-width: 70px;
  text-align: end;
  margin: 5px;
}

.labelled-date-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-date-picker .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-date-picker .react-datepicker-wrapper {
  flex: 1
}
.labelled-date-picker .react-datepicker__input-container {
  display: flex;
}
.labelled-date-picker input {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}
.labelled-date-picker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.labelled-date-picker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.labelled-date-picker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.labelled-date-picker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -16px;
}

.labelled-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-picker .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-picker select {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}

.labelled-picker-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-picker-check .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-picker-check select {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}

.labelled-multi-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-multi-picker .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-multi-picker .multi-picker {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.labelled-multi-picker .multi-picker .multi-picker-selector {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.labelled-multi-picker .multi-picker .multi-picker-selector select {
  flex: 1;
  border: 1px solid #000;
  border-radius: 2px;
}
.labelled-multi-picker .multi-picker .multi-picker-selected {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.labelled-multi-picker .multi-picker .multi-picker-selected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.labelled-image-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.labelled-image-picker .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-image-picker input {
  display: none;
}
.labelled-image-picker .image-picker {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #ddd;
  padding: 10px;
  min-width: 250px;
  min-height: 100px;
}
.labelled-image-picker .image-picker img {
  max-width: 512px;
}
.labelled-image-picker .image-picker .button-remove {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255,255,255,0.8);
}
.labelled-image-picker .image-picker:hover .button-remove {
  display: inherit;
}
.labelled-image-picker .image-picker .drop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(193, 193, 193, 0.9);
  font-size: 2rem;
  color: #444;
  border: 4px dashed #444;
  font-weight: bold;
}

.labelled-image {
  display: flex;
  flex-direction: row;
  margin: 3px;
  border-bottom: 1px solid #ddd;
}
.labelled-image .label {
  margin-right: 5px;
  width: 200px;
}
.labelled-image img {
  max-width: 512px;
}

.form-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.form-actions > * {
  margin: 5px;
}

.form-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-links > * {
  margin: 5px;
}

.report-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.report-links > * {
  margin: 5px;
  font-size: 1.2rem;
}

.settings-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.settings-links > * {
  margin: 5px;
  font-size: 1.2rem;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dropdown-button .dropdown-label {
  margin-right: 5px;
}
.dropdown-content {
  position: absolute;
  width: 250px;
  right: calc(50% - 125px);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  display: none;
}
@media (min-width: 960px) {
  .dropdown-content {
    right: 0px;
  }
}
.dropdown-content.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dropdown-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding:5px;
}
.dropdown-option:last-child {
  border-bottom: none;
}
.dropdown-option > * {
  margin-right: 5px;
}
.dropdown-option > *:last-child {
  margin-right: 0px;
}

.roles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.pill {
  padding: 2px 10px;
  /* border: 1px solid #ddd; */
  border-radius: 20px;
  background-color: #333;
  color: #fff;
  display: inline-block;
}
.pill-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pill-content {
  margin-right: 5px;
}
.pill-button:hover {
  opacity: 0.5;
}
.pill-button:active {
  color: 'red';
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload .drag-and-drop {
  height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #ddd;
}
.upload .drop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(193, 193, 193, 0.9);
  font-size: 2rem;
  color: #444;
  border: 4px dashed #444;
  font-weight: bold;
}
.modal-footer .actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.bubble-notification {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 10px;
  width: calc(100% - 100px);
  min-height: 50px;
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 500ms;
  pointer-events: none;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.bubble-notification.open {
  opacity: 1;
  pointer-events: all;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}
@keyframes spinner {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.field-containers {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;
}
.list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #eee;
  min-height: 100px;
}
.list-title {
  text-align: center;
}
.field-droppable {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.exclude-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ddd;
  min-height: 100px;
}
.exclude-droppable {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.item-container {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #444;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.exclude-droppable .item-container {
  color: #888;
  border-color: #aaa;
}

.plans-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-subscription {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  cursor: pointer;
}

.card-spaced {
  padding: 10px;
  margin: 20px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
}
.card-selected {
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4),
    5px 5px 15px rgba(0,170,0,0.2);
  border: 2px solid #00aa00;
}
.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.card-selected .card-icon {
  color: #00aa00;
}

.subscription-buttons > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.user-details {
  margin-bottom: 20px;
}

.empty-body {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.empty-text {
  color: #888;
  font-size: 2.6rem;
  pointer-events: all;
}
.empty-text-small {
  color: #888;
  font-size: 1.6rem;
  pointer-events: all;
}

.page .valve-exercise, .page .record-exercise,
.page .hydrant-exercise,
.page .hydrant-test {
  display: flex;
  flex-direction: column;
}

.fireflow-header {
  display: flex;
  flex-direction: row;
}
.fireflow-header .company-info {
  flex: 1;
}
.fireflow-info {
  flex: 1;
  margin: 10px 0px;
}
.fireflow-classification {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #CCFAFF;
  border: solid 2px #000;
  padding: 10px 0px;
}
.fireflow-classification-title {
  font-weight: bold;
  text-decoration: underline;
}
.fireflow-classification-code {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-around;
  background-color: blue;
  color: white;
  margin: 5px 0px;
}
.fireflow-conclusion {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.fail {
  color: red;
}
.success {
  color: green;
}

.chart {
  align-self: center;
  width: 100%;
  max-width: 8.5in;
}
.chart canvas {
  max-width: 100%;
}


@media not print {
  .print-only {
    display: none;
  }
}
@media print {
  body, #root {
    height: auto;
  }
  header, aside {
    display: none;
  }
  main {
    position: inherit;
  }
  .page {
    max-width: 8.5in;
  }
  aside + main {
    padding-left: 0px;
    padding-right: 0px;
  }
  table.report-table {
    page-break-inside: auto;
    margin-bottom: 0px;
  }
  .valve-results-report table.report-table > tbody > tr, .record-results-report table.report-table > tbody > tr {
    display: block;
  }
  table.report-table > tbody > tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .no-print {
    display: none;
  }
  .page-break {
    page-break-after: always;
  }
  .report,
  .valve-exercise, .record-exercise,
  .hydrant-exercise,
  .hydrant-test {
    font-size: 12px;
    max-width: 8.5in;
  }
  .report, main, .body {
    overflow: visible;
  }
}

.report {
  max-width: 8.5in;
}

.report table {
  width: 100%;
}

.report-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.report-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.report-header .header-logo {
  max-width: 50%;
  margin-right: 20px;
}
.report-header .header-logo img {
  width: 100%;
  max-height: 100px;
  object-fit: contain;
}
.report label,
.valve-exercise label, .record-exercise label,
.hydrant-exercise label,
.hydrant-test label {
  margin-right: 5px;
  text-decoration: underline;
  font-weight: 700;
}

.report-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

table.report-table {
  margin-bottom: 20px;
}
.report thead td {
  border-bottom: 1px solid #000;
}
.report tbody td {
  padding: 11px 0px;
  border-bottom: 1px solid #000;
}

.valve-summary, .record-summary {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #000;
}
.summary-details {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}
.valve-details, .record-details {
  --grid-layout-gap: 5px;
  --grid-column-count: 3;
  --grid-item-min-width: 200px;

  /*
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  background-color: #CCFAFF;
  border: solid 2px #000;
  padding: 10px;
}
.valve-stats, .record-stats, .exercise-stats {
  --grid-layout-gap: 5px;
  --grid-column-count: 4;
  --grid-item-min-width: 200px;

  /*
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  background-color: #f5f5f5;
  border: solid 2px #000;
  padding: 10px;
}

.full-row {
  grid-column: 1 / -1;
}


.result-details {
  --grid-layout-gap: 5px;
  --grid-column-count: 4;
  --grid-item-min-width: 200px;

  /*
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  background-color: #CCFAFF;
  border: solid 2px #000;
  padding: 10px;
}

.valve-settings, .record-settings {
  --grid-layout-gap: 5px;
  --grid-column-count: 4;
  --grid-item-min-width: 200px;

  /*
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  background-color: #f5f5f5;
  border: solid 2px #000;
  border-top: none;
  padding: 10px;
}

.valve-location, .record-location, .location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
}
.valve-location-info, .record-location-info, .location-info {
  display: flex;
  flex-direction: column;
}
.valve-location-detail, .record-location-detail, .location-detail {
  display: flex;
  flex-direction: row;
}
.valve-location-detail label, .record-location-detail label, .location-detail label {
  width: 100px;
  text-align: right;
}
.valve-location-detail span, .record-location-detail span, .location-detail span {
  width: 300px;
  border-bottom: 1px solid #000;
}
.valve-gps-info, .record-gps-info, .gps-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  border: 2px solid #000;
  width: 300px;
}
.valve-gps-detail, .record-gps-detail, .gps-detail {
  display: flex;
  flex-direction: row;
}
.valve-gps-detail label, .record-gps-detail label, .gps-detail label {
  width: 100px;
  text-align: end;
}

.valve-info, .record-info, .hydrant-info {
  --grid-layout-gap: 5px;
  --grid-column-count: 4;
  --grid-item-min-width: 200px;

  /*
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  padding: 10px;
  margin: 10px 0px;
}

.table-exercises-container {
  border: 1px solid #000;
  padding: 20px 0px;
  margin: 20px 0px;
}
.table-exercises thead th {
  padding: 10px 10px 0px 10px;
}
.table-exercises thead th span {
  border-bottom: 1px solid #000;
}
.table-exercises tbody td {
  padding: 5px 10px;
}

.action-cell {
  position: relative;
}
.row-confirm {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.command-row {
  width: 30px;
}

.latlong-modal .modal-body {
  height: 500px;
  width: 500px;
}
.latlong-modal .map-container .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: crosshair !important;
}
.map-select-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.map-select-image {
  height: 20px;
  width: 20px;
}

.actionable-input-group {
  display: flex;
  flex-direction: row;
}
.actionable-inputs {
  flex: 1;
}
.input-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}